import styled from 'styled-components';
import {Link} from 'gatsby-plugin-intl';

import {MEDIA} from '../../constants';
import {getSpacing} from '../../theme/helpers';

export const LogoWrap = styled(Link)`
  padding: ${getSpacing('basic')} ${getSpacing('xs')};

  @media (min-width: ${MEDIA.MIN}) {
    padding: ${getSpacing('md')};
  }
`;

export const LogoImg = styled.img`
  height: 50px;

  @media (min-width: ${MEDIA.MIN}) {
    height: 75px;
  }

  @media (min-width: ${MEDIA.MED}) {
    height: 95px;
  }
`;
