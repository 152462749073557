import React, {useState} from 'react';
import {string} from 'prop-types';

import {Navigation, Toggle, Hamburger, Navbox} from './Navbar.styles';
import NavbarLinks from './NavbarLinks';
import Logo from '../Logo';

const Navbar = ({path}) => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  return (
    <Navigation>
      <Logo/>
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <Hamburger open={navbarOpen}/>
      </Toggle>
      {navbarOpen ? (
        <Navbox>
          <NavbarLinks onNavItemClick={() => setNavbarOpen(false)} path={path}/>
        </Navbox>
      ) : (
        <Navbox open>
          <NavbarLinks path={path}/>
        </Navbox>
      )}
    </Navigation>
  )
}

Navbar.propTypes = {
  path: string.isRequired,
}
export default Navbar;
