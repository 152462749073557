const scope = 'components.Footer';

export default {
  header: {
    id: `${scope}.header`,
    defaultMessage: 'KONTAKT',
  },
  blog: {
    id: `${scope}.blog`,
    defaultMessage: 'Blog',
  },
  dddKrk: {
    id: `${scope}.dddKrk`,
    defaultMessage: 'ddd-krk',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Polityka Prywatności',
  },
  mailImgAlt: {
    id: `${scope}.mailImgAlt`,
    defaultMessage: 'mail-ikona',
  },
  twitterImgAlt: {
    id: `${scope}.twitterImgAlt`,
    defaultMessage: 'twitter-ikona',
  },
  linkedInImgAlt: {
    id: `${scope}.linkedInImgAlt`,
    defaultMessage: 'linkedin-ikona',
  },
  subheader: {
    id: `${scope}.subheader`,
    defaultMessage: 'Jeśli chciałbyś nawiązać współpracę, kontakt albo zadać mi pytanie - napisz do mnie:',
  },
}
