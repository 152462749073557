import React from 'react';
import {ThemeProvider} from 'styled-components';
import {string, node} from 'prop-types';

import Navbar from '../Navbar';
import Footer from '../../components/Footer';
import CookieConsent from '../../components/CookieConsent';
import {Wrapper} from './Layout.styles';
import {theme} from '../../theme/theme';
import GlobalStyle from '../../theme/global-styles';

const Layout = ({children, path}) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle/>
    <Wrapper>
      <Navbar path={path}/>
      {children}
      <Footer/>
    </Wrapper>
    <CookieConsent/>
  </ThemeProvider>
);

Layout.propTypes = {
  children: node.isRequired,
  path: string.isRequired,
}

export default Layout;
