import React from 'react';
import ReactCookieConsent from 'react-cookie-consent';
import {useIntl} from 'gatsby-plugin-intl';

import messages from './messages';

const CookieConsent = () => {
    const {formatMessage} = useIntl();

    return (
      <ReactCookieConsent
        location="bottom"
        buttonText={formatMessage(messages.acceptButtonMsg)}
        cookieName="cookie-consent"
        style={{background: "#F8F8F8", display: 'flex', alignItems: 'center', color: '#707070', boxShadow: '0 -4px 4px -6px #707070'}}
        buttonStyle={{color: "#ffffff", backgroundColor: '#03B982', borderRadius: '5px', fontSize: '16px', padding: '12px'}}
      >
          {formatMessage(messages.text)}
      </ReactCookieConsent>
    );
}

export default CookieConsent;
