import messages from './messages';

export const NAVBAR_LINKS = [
  {
    message: messages.trainings,
    id: '#trainings'
  },
  {
    message: messages.references,
    id: '#references'
  },
  // {
  //   text: messages.presentations,
  //   to: '/#presentations'
  // },
  {
    message: messages.contact,
    id: '#contact',
  },
];
