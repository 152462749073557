import React from 'react';
import {useIntl} from 'gatsby-plugin-intl';

import {
  StyledFooter,
  ContactInfo,
  ContactWrapper,
  ExternalLinksWrapper,
  ExternalLink,
  Wrapper,
  Header,
  Subheader,
  InternalLink,
} from './Footer.styles';
import messages from './messages';
import mailImg from '../../../static/mail.svg';
import twitterImg from '../../../static/twitter.svg';
import linkedInImg from '../../../static/linkedin.svg';
import Container from '../Container';
import {CONTACT_EMAIL_ADDRESS} from '../../constants';

const TWITTER_ACCOUNT = '@pwyczes';
const TWITTER_LINK = 'https://twitter.com/pwyczes';
const LINKEDIN_LINK = 'https://www.linkedin.com/in/pwyczes';
const LINKEDIN_ACCOUNT = '/in/pwyczes';
const BLOG_LINK = 'https://www.eventuallyinconsistent.com/';
const DDD_KRK_LINK = 'https://www.meetup.com/DDD-KRK/';
const PRIVACY_POLICY = '/polityka-prywatnosci';

const Footer = () => {
  const {formatMessage} = useIntl();

  return (
    <Wrapper>
      <Container id="contact">
        <StyledFooter>
          <Header>{formatMessage(messages.header)}</Header>
          <Subheader>{formatMessage(messages.subheader)}</Subheader>
          <ContactWrapper>
            <ContactInfo>
              <a href={`mailto:${CONTACT_EMAIL_ADDRESS}`}>
                <img src={mailImg} alt={formatMessage(messages.mailImgAlt)}/>
                {CONTACT_EMAIL_ADDRESS}
              </a>
            </ContactInfo>
            <ContactInfo>
              <a
                href={TWITTER_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitterImg} alt={formatMessage(messages.twitterImgAlt)}/>
                {TWITTER_ACCOUNT}
              </a>
            </ContactInfo>
            <ContactInfo noSpacing>
              <a
                href={LINKEDIN_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedInImg} alt={formatMessage(messages.linkedInImgAlt)}/>
                {LINKEDIN_ACCOUNT}
              </a>
            </ContactInfo>
          </ContactWrapper>
          <ExternalLinksWrapper>
            <ExternalLink
              href={BLOG_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage(messages.blog)}
            </ExternalLink>
            <ExternalLink
              href={DDD_KRK_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage(messages.dddKrk)}
            </ExternalLink>
            <InternalLink to={PRIVACY_POLICY}>
              {formatMessage(messages.privacyPolicy)}
            </InternalLink>
          </ExternalLinksWrapper>
        </StyledFooter>
      </Container>
    </Wrapper>

  );
}

export default Footer;
