import React from 'react';
import {useIntl} from 'gatsby-plugin-intl';

import {LogoWrap, LogoImg} from './Logo.styles';
import messages from './messages';
import {MAIN_URL} from '../../constants'
import logo from '../../../static/pattern-applied-logo.svg'

const Logo = () => {
  const {formatMessage} = useIntl();

  return (
    <LogoWrap to={MAIN_URL}>
      <LogoImg src={logo} alt={formatMessage(messages.logoAlt)}/>
    </LogoWrap>
  )
}

export default Logo
