import styled from 'styled-components'
import {MEDIA} from '../../constants';
import {getSpacing} from '../../theme/helpers';

export const Container = styled.div`
  margin: 0 ${getSpacing('md')};

  @media (min-width: ${MEDIA.MAX}) {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 ${getSpacing('md')};
  }
`

export default Container;
