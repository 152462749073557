import styled, {css} from 'styled-components';
import {Link} from 'gatsby-plugin-intl';

import {MEDIA} from '../../constants';
import {getColor, getSpacing, getTypographySize, getTypographyColor} from '../../theme/helpers';

const afterElementStyles = css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0;
  content: ".";
  color: transparent;
  background-color: ${getColor('brandBlue')};
  height: 1px;
  transition: width 0.25s ease-in;
`;

const navItemCommonStyles = css`
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  padding: ${getSpacing('md')};
  transition: all 200ms ease-in;
  position: relative;
  color: ${getTypographyColor('grey')};
  font-size: ${getTypographySize('md')};
  z-index: 6;
  @media (min-width: ${MEDIA.MED}) {
    margin: ${getSpacing('md')};
    padding: ${getSpacing('xmd')} 0;
    font-size: 20px;

    :after {
      ${afterElementStyles}
    }

    :hover {
      color: ${getColor('brandBlue')};

      ::after {
        width: 100%;
      }
    }
  }

`;

export const NavItem = styled(Link)`
  ${navItemCommonStyles};
  cursor: pointer;
`
export const NavItemButton = styled.button`
  ${navItemCommonStyles};
  color: ${({isActive, theme}) => isActive ? theme.typography.color.darkGrey : theme.typography.color.grey};
  font-weight: ${({isActive, theme}) => isActive ? theme.typography.weight.bold : theme.typography.weight.normal};
  border: none;
  margin: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  cursor: pointer;
  outline: none;
  padding: ${getSpacing('md')} ${getSpacing('sm')};

  /* Normalize \`line-height\`. Cannot be changed from \`normal\` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable \`input\` types in iOS */
  -webkit-appearance: none;

  @media (min-width: ${MEDIA.MED}) {
    margin: ${getSpacing('md')} ${getSpacing('sm')};
  }

  @media (min-width: ${MEDIA.MAX}) {
    margin: ${getSpacing('md')} ${getSpacing('xxs')};
  }
`;

export default NavItem;
