const scope = 'components.CookieConsent';

export default {
  acceptButtonMsg: {
    id: `${scope}.acceptButtonMsg`,
    defaultMessage: 'Akceptuję',
  },
  text: {
    id: `${scope}.text`,
    defaultMessage: 'Ta strona wykorzystuje tzw. pliki cookies w celu prawidłowego świadczenia usługi i wyświetlania informacji oraz w celu gromadzenia anonimowych danych statystycznych. Możesz wyłączyć obsługę cookies przez zmianę ustawień swojej przeglądarki internetowej.',
  }
}
