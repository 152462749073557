import styled from 'styled-components';

import {NAVBAR_HEIGHT, MEDIA} from '../../constants';
import {getColor} from '../../theme/helpers';

export const Wrapper = styled.div`
  background-color: ${getColor('backgroundGray')};
  margin-top: ${NAVBAR_HEIGHT.SMALL};

  @media (min-width: ${MEDIA.MIN}) {
    margin-top: ${NAVBAR_HEIGHT.MEDIUM};
  }

  @media (min-width: ${MEDIA.MED}) {
    margin-top: 0;
  }
`;
