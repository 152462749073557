import styled from 'styled-components';
import { Link } from 'gatsby';

import {MEDIA} from '../../constants';
import {getColor, getSpacing, getTypographyColor, getTypographySize, getTypographyWeight} from '../../theme/helpers';

export const Wrapper = styled.div`
  padding: ${getSpacing('xxl')} ${getSpacing('sm')};
  background-color: ${getColor('brandBlue')};
  display: flex;

  @media (min-width: ${MEDIA.MED}) {
    min-height: 350px;
    align-items: center;
  }
`;

export const Header = styled.h3`
  font-size: ${getTypographySize('xl')};
  color: ${getTypographyColor('basicWhite')};
  text-align: center;
  font-weight: ${getTypographyWeight('bold')};
  width: 100%;
  padding-bottom: ${getSpacing('lg')};

  @media (min-width: ${MEDIA.MIN}) {
    font-size: ${getTypographySize('xxl')};
  }
`;

export const Subheader = styled.p`
  font-size: ${getTypographySize('md')};
  color: ${getTypographyColor('basicWhite')};
  text-align: center;
  width: 100%;

  @media (min-width: ${MEDIA.MIN}) {
    padding-bottom: ${getSpacing('lg')};
  }
`;

export const StyledFooter = styled.footer`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const ContactInfo = styled.span`
  color: ${getTypographyColor('basicWhite')};
  size: ${getTypographySize('md')};
  display: inline-flex;
  align-items: center;
  padding: ${getSpacing('sm')} 0;
  transition: all 0.25s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  img {
    margin-right: ${getSpacing('basic')};
  }

  @media (min-width: ${MEDIA.MED}) {
    margin-right: ${({ noSpacing }) => noSpacing ? 0 : '50px'};
  }

  a {
    text-decoration: none;
    color: ${getTypographyColor('basicWhite')};
    display: inline-flex;
    align-items: center;
  }
`;

export const ContactWrapper = styled.div`
  padding: ${getSpacing('sm')} 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: ${MEDIA.MED}) {
    padding-bottom: ${getSpacing('xl')};
    flex-direction: row;
  }
`;

export const ExternalLinksWrapper = styled.div`
  width: 100%;
  padding-top: ${getSpacing('xl')};
  border-top: 1px solid white;
  opacity: 0.5;

  @media (min-width: ${MEDIA.MED}) {
    margin-left: auto;
  }
`;

export const ExternalLink = styled.a`
  width: 100%;
  color: ${getTypographyColor('basicWhite')};
  margin-right: ${getSpacing('md')};
  text-decoration: none;
  position: relative;
  padding-bottom: ${getSpacing('sm')};

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    content: ".";
    color: transparent;
    background-color: ${getColor('basicWhite')};
    height: 1px;
    transition: width 0.25s ease-in;
  }

  :hover {
    ::after {
      width: 100%;
    }
  }
`;

export const InternalLink = styled(Link)`
  width: 100%;
  color: ${getTypographyColor('basicWhite')};
  margin-right: ${getSpacing('md')};
  text-decoration: none;
  position: relative;
  padding-bottom: ${getSpacing('sm')};

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    content: ".";
    color: transparent;
    background-color: ${getColor('basicWhite')};
    height: 1px;
    transition: width 0.25s ease-in;
  }

  :hover {
    ::after {
      width: 100%;
    }
  }
`;
