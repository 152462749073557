const scope = 'components.Navbar';

export default {
  trainings: {
    id: `${scope}.trainings`,
    defaultMessage: 'Szkolenia',
  },
  references: {
    id: `${scope}.references`,
    defaultMessage: 'Referencje',
  },
  presentations: {
    id: `${scope}.presentations`,
    defaultMessage: 'Prezentacje',
  },
  contact: {
    id: `${scope}.contact`,
    defaultMessage: 'Kontakt',
  },
  languagePL: {
    id: `${scope}.languagePL`,
    defaultMessage: 'PL',
  },
  languageEN: {
    id: `${scope}.languageEN`,
    defaultMessage: 'EN',
  },
}
