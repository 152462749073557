import styled from 'styled-components';

import {NAVBAR_HEIGHT, MEDIA} from '../../constants';
import {getColor, getSpacing, getTypographyColor, getTypographySize} from '../../theme/helpers';

export const Navigation = styled.nav`
  display: flex;
  max-width: 2500px;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 0 ${getSpacing('xs')};
  background-color: ${getColor('backgroundGray')};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;

  @media (min-width: ${MEDIA.MED}) {
    padding: 0 ${getSpacing('xl')};
    position: static;
  }
`

export const Toggle = styled.div`
  display: flex;
  height: 100%;
  cursor: pointer;
  padding: ${getSpacing('md')};

  @media (min-width: ${MEDIA.MED}) {
    display: none;
  }
`

export const Navbox = styled.div`
  background-color: ${getColor('backgroundGray')};
  display: flex;
  align-items: center;

  flex-direction: column;
  position: fixed;
  width: 100%;
  justify-content: flex-start;
  transition: all 0.3s ease-in;
  top: ${NAVBAR_HEIGHT.SMALL};
  left: ${props => (props.open ? "-100%" : "0")};
  z-index: 100;

  @media (min-width: ${MEDIA.MIN}) {
    top: ${NAVBAR_HEIGHT.MEDIUM};
    justify-content: flex-end;
  }

  @media (min-width: ${MEDIA.MED}) {
    flex-direction: row;
    position: inherit;
    margin-top: auto;
  }
`

export const Hamburger = styled.div`
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};
  background-color: ${getColor('brandBlue')};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
    background-color: ${getColor('brandBlue')};
  }

  ::before {
    transform: ${props =>
            props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`;

export const StyledSeparator = styled.span`
  color: ${getTypographyColor('grey')};
  font-size: ${getTypographySize('md')};
`;
