import React from 'react';
import {func, string} from 'prop-types';
import {changeLocale, useIntl, FormattedMessage} from 'gatsby-plugin-intl';

import {NAVBAR_LINKS} from './config';
import {SUPPORTED_LOCALES, MAIN_URL} from '../../constants';
import messages from './messages';
import {NavItem, NavItemButton} from '../NavItem';
import {StyledSeparator} from './Navbar.styles'

const NavbarLinks = ({onNavItemClick, path}) => {

  const handleLocaleChange = (locale) => {
    changeLocale(locale);
    onNavItemClick();
  }

  const {locale} = useIntl();

  const getLink = (id) => {
    if (id === '#contact' && path !== MAIN_URL) {
      const pathWithoutLocale = path.replace(`/${locale}`, '')
      return `${pathWithoutLocale}${id}`;
    }
    return `/${id}`;
  }

  return (
    <>
      {NAVBAR_LINKS.map(({id, message}) =>
        <NavItem
          key={id}
          to={getLink(id)}
          onClick={onNavItemClick}
        >
          <FormattedMessage {...message} />
        </NavItem>
      )}
      <div>
        <NavItemButton
          onClick={() => handleLocaleChange(SUPPORTED_LOCALES.pl)}
          isActive={locale === SUPPORTED_LOCALES.pl}
        >
          <FormattedMessage {...messages.languagePL} />
        </NavItemButton>
        <StyledSeparator>/</StyledSeparator>
        <NavItemButton
          onClick={() => handleLocaleChange(SUPPORTED_LOCALES.en)}
          isActive={locale === SUPPORTED_LOCALES.en}
        >
          <FormattedMessage {...messages.languageEN} />
        </NavItemButton>
      </div>
    </>
  );
}

NavbarLinks.propTypes = {
  onNavItemClick: func,
  path: string.isRequired,
}

NavbarLinks.defaultProps = {
  onNavItemClick: () => null,
}

export default NavbarLinks;
